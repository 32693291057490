.roulette-container {
    position: relative;
    width: 250px;
    /* Imposta la larghezza desiderata */
    height: 250px;
    /* Imposta l'altezza desiderata */
}

.roulette {
    width: 100%;
    height: 100%;
    background-image: url('../assets/wheel.png');
    background-size: cover;
    transition: transform 0.05s ease-out;
    /* Aggiunge transizione alla rotazione */
}

.ball {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    /* Imposta la larghezza desiderata */
    height: 20px;
    /* Imposta l'altezza desiderata */
    background-color: rgb(227, 168, 16);
    /* Cambia il colore se preferisci */
    border-radius: 50%;
    transform-origin: center center;
}