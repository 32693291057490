@font-face {
  font-family: "pixel1";
  src: url("./fonts/Minecraft.ttf") format("truetype");
  font-weight: bolder;
  /* Peso del font "regular" */
  font-style: bold;
}

@font-face {
  font-family: "pixel2";
  src: url("./fonts/Retro\ Gaming.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "pixel3";
  src: url("./fonts/ONESIZE_.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: #13083d;
}

* {
  font-family: "pixel3", sans-serif;
}

.tit {
  font-family: "pixel3", sans-serif;
}

.normalFont {
  font-family: "Hind";
  font-weight: bold;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: inherit;
}

.highlighted-border::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0px;
  width: 3px;
  /* Larghezza del bordo luminoso */
  height: 100%;
  background: rgb(255, 218, 253);
  box-shadow: 0 0 15px rgb(255, 118, 118);
  /* Aggiunge luminosità */
  z-index: 100;
}

.no-scrollbar {
  scrollbar-width: none;
  /* Per Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  /* Per Chrome, Safari e Edge */
}
.container-blur {
  position: relative;
  overflow: hidden; /* Nasconde il contenuto che va oltre i bordi */
}

.content-blur {
  position: relative;
  z-index: 1; /* Imposta il contenuto in secondo piano rispetto all'overlay */
}

.overlay-blur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(7px);
  z-index: 2;
  border-radius: 15px;
}
.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(
    -50%,
    -50%
  ); /* Centra il testo sia orizzontalmente che verticalmente */
  z-index: 3; /* Sopra tutto */
}

* {
  box-sizing: border-box;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  display: none;
}
