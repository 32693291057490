:root {
    --glow-hue: 222deg;
    --shadow-hue: 180deg;
    --spring-easing: linear(
        0, 0.002, 0.01 0.9%, 0.038 1.8%, 0.156, 0.312 5.8%, 0.789 11.1%, 1.015 14.2%,
        1.096, 1.157, 1.199, 1.224 20.3%, 1.231, 1.231, 1.226, 1.214 24.6%,
        1.176 26.9%, 1.057 32.6%, 1.007 35.5%, 0.984, 0.968, 0.956, 0.949 42%,
        0.946 44.1%, 0.95 46.5%, 0.998 57.2%, 1.007, 1.011 63.3%, 1.012 68.3%,
        0.998 84%, 1
    );
    --spring-duration: 5s; /* Aumentata la durata per rendere l'animazione più lenta */
}

@property --shimmer {
    syntax: "<angle>";
    inherits: false;
    initial-value: 33deg;
}

@keyframes shimmer {
    0% {
        --shimmer: 0deg;
    }
    100% {
        --shimmer: 360deg;
    }
}

@keyframes shine {
    0% {
        opacity: 0.5;
    }
    15% {
        opacity: .8;
    }
    55% {
        opacity: .8;
    }
    100% {
        opacity: 0.5;
    }
}

@keyframes text {
    0% {
        background-position: 100% center;
    }    
    100% {
        background-position: -100% center;
    }    
}

.glow-btn {
    color: var(--bg);
    font-weight: 600;
    background-color: transparent;
    width: 100%;
    height: 100%;
    position: relative;
    isolation: isolate;
    border: none;
    box-shadow: 0 2px 3px 1px hsl(var(--glow-hue) 50% 20% / 50%), inset 0 -10px 20px -10px hsla(var(--shadow-hue),10%,90%,95%);
    border-radius: 100%;
    scale: 1;
    transition: all var(--spring-duration) var(--spring-easing);
    animation: shine var(--spring-duration) ease-in infinite alternate;
}

.shimmer {
    position: absolute;
    inset: -40px;
    border-radius: inherit;
    mask-image: conic-gradient(
        from var(--shimmer, 0deg),
        transparent 0%,
        transparent 10%,
        black 36%,
        black 45%,
        transparent 50%,
        transparent 60%,
        black 85%,
        black 95%,
        transparent 100%
    );
    mask-size: cover;
    mix-blend-mode: plus-lighter;
    animation: shimmer var(--spring-duration) linear infinite both;
}

.shimmer::before,
.shimmer::after {
    transition: all 0.5s ease;
    opacity: 1;
    content: "";
    border-radius: inherit;
    position: absolute;
    mix-blend-mode: color;
    inset: 40px;
    pointer-events: none;
    animation: shine var(--spring-duration) ease-in infinite alternate;
}

.shimmer::before {
    box-shadow: 0 0 3px 2px hsl(var(--glow-hue) 20% 95%),
        0 0 7px 4px hsl(var(--glow-hue) 20% 80%),
        0 0 13px 4px hsl(var(--glow-hue) 50% 70%),
        0 0 25px 5px hsl(var(--glow-hue) 100% 70%);
    z-index: -1;
}

.shimmer::after {
    box-shadow: inset 0 0 0 1px hsl(var(--glow-hue) 70% 95%),
        inset 0 0 2px 1px hsl(var(--glow-hue) 100% 80%),
        inset 0 0 5px 2px hsl(var(--glow-hue) 100% 70%);
    z-index: 2;
}

.glow-btn .text {
    color: white;
    font-size: 18px;
    background-clip: text;
    background-color: var(--bg);
    background-image: linear-gradient(120deg, transparent, hsla(var(--glow-hue),100%,80%,0.66) 40%, hsla(var(--glow-hue),100%,90%,.9) 50%, transparent 52%);
    background-repeat: no-repeat;
    background-size: 300% 300%;
    background-position: center 150%; /* Riduci il range di movimento */
    animation: text 5s ease-in-out infinite; /* Animazione più lenta */
}

