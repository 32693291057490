.checkGame {
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-top: 20px;
}

.players>div {
    display: inline-block;
    border: 3px solid wheat;
    font-size: 30px;
    padding: 5px 10px;
    min-width: 250px;
    margin: 0 0 10px 0;
    cursor: pointer;
}

.game-board {
    display: inline-block;
    border: 10px solid #777378;
    position: relative;
}

.game-status {
    display: block;
    padding: 5px;
    color: white;
    font-family: 'Righteous', sans-serif;
    width: 55%;
    max-width: 500px;
    font-size: 30px;
    position: relative;
    top: 15px;
    z-index: 3;
    margin: 30px auto;
}

.time-travel {
    max-width: 660px;
    display: block;
    margin: 0 auto;
    text-align: right;
}

.undo {
    font-size: 15px;
    padding: 5px;
    border-radius: 0;
    background-color: #fff;
    box-shadow: none;
    border: 3px solid #deb887;
    margin: 5px;
    cursor: pointer;
}

.undo.disabled {
    cursor: not-allowed;
    opacity: .4;
}

.board-col {
    display: inline-block;
    max-width: 80px;
}

.square {
    background: no-repeat #EEBF7F;
    border: none;
    height: 80px;
    width: 80px;
    padding: 5px;
    background-origin: content-box;
    background-size: contain;
}

.isActive {
    background-color: wheat;
}

.square.movable.player1-move {
    background-color: #7fffd4;
}

.square.movable.player2-move {
    background-color: #7fffd4;
}

.square.player1,
.square.player2,
.square.movable {
    cursor: pointer;
}

.square.black {
    background-color: #611901;
}

.square.player1 {
    background-image: url("img/red-pawn.png");
}

.square.player2 {
    background-image: url("img/blue-pawn.png");
}

.square.player1.king {
    background-image: url("img/red-king.png");
}

.square.player2.king {
    background-image: url("img/blue-king.png");
}

@media only screen and (max-width: 700px) {

    .board-col {
        max-width: 60px;
    }

    .square {
        height: 60px;
        width: 60px;
    }
}

@media only screen and (max-width: 520px) {

    .board-col {
        max-width: 50px;
    }

    .square {
        height: 50px;
        width: 50px;
    }
}

@media only screen and (max-width: 440px) {

    .board-col {
        max-width: 40px;
    }

    .square {
        height: 40px;
        width: 40px;
    }
}

@media only screen and (max-width: 370px) {

    .board-col {
        max-width: 35px;
    }

    .square {
        height: 35px;
        width: 35px;
    }
}