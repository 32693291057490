.primary-button {
  background: linear-gradient(to top right, #31aab8, #be25b9);
  box-shadow: 0px 0px 30px rgba(11, 153, 224, 0.4);
  border: none;
  border-radius: 50px;
  color: white;
  padding: 14px 30px;
  cursor: pointer;
  transition: 100ms;
  font-weight: bold;
  letter-spacing: 1.5px;
  font-size: 15px;
  font-family: "pixel1";
}

.secondary-button {
  background: linear-gradient(to top right, #31aab8, #0e6f79);
  box-shadow: 0px 0px 24px rgba(11, 153, 224, 0.3);
  border: none;
  border-radius: 20px;
  color: white;
  padding: 6px 16px;
  cursor: pointer;
  transition: background-color 300ms;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 1px;
}

.secondary-button:hover {
  background-color: rgb(42, 47, 147);
}

.text-button {
  background-color: transparent;
  border: none;
  color: #31aab8;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
}

.text-button:hover {
  background-color: rgb(42, 47, 147);
}

.horizontal-scroll {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}

.scroll-item {
  display: inline-block;
  margin-right: 10px;
}

@keyframes scrollAnimation {
  0% {
    transform: translateX(0%); /* Inizia fuori dalla pagina a destra */
  }
  100% {
    transform: translateX(-100%); /* Finisce fuori dalla pagina a sinistra */
  }
}

.horizontal-scroll-animated {
  display: flex;
  overflow: hidden; /* Nasconde gli elementi che escono dal contenitore */
  white-space: nowrap;
  width: 100%; /* Può essere modificato per avere una larghezza fissa */
}

.scroll-content {
  display: flex;
  animation: scrollAnimation linear infinite; /* Animazione continua */
}

.gridCasino {
  grid-template-columns: repeat(auto-fill, minmax(90px, 130px));
}
/* Contenitore del dropdown */
.dropdown-container {
  position: relative;
  display: inline-block;
  z-index: 9;
}

/* Pulsante che apre il menu */
.dropdown-button {
  background-color: #1c213d;
  color: white;
  padding: 10px 6px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

/* Lista del dropdown */
.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 200px;
  max-height: 300px; /* Limita l'altezza del menu */
  background-color: #1c213d;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 0;
  color: white;
  margin: 0;
  list-style-type: none;
  overflow-y: auto; /* Abilita lo scorrimento */
}

/* Elementi della lista */
.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #291e69;
}
